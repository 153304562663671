<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/questionList"
            >问题列表 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{operation == 1 ? '新建': '编辑'}}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">提问：</span>
        <div class="right_Div">
          <a-input v-model="title" class="all_input" placeholder="请输入标题" />
        </div>
      </div>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">回答：</span>
          <UE
            editorId="introduceEditor1"
            :initContent="content"
            @input="getContent"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">所属分类：</span>
        <div class="right_Div">
          <a-select
            class="search-input"
            placeholder="请选择分类"
            v-model="cpTypeId"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="(item, index) in tableData"
              :key="index"
              :value="item.cpId"
            >
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button type="primary" class="btn" :loading="PreventLoad" @click="handleOk()">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      content: "", //内容
      cpTypeId: null, //分类id
      title: null, // 标题
      operation: 1,
      id: null, // id
      tableData: [],
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this, "content", e);
    },
    // 获取数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/information/problem/category/list",
        method: "get",
        params: {
          page: 1,
          size: 9999,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
        }
        // this.total = res.data.total;
      });
    },
    // 确定新建
    handleOk() {
      if (!this.title) {
        this.$message.warning("请输入标题");
        return;
      }
      if (!this.content) {
        this.$message.warning("请输入回答");
        return;
      }
      if (!this.cpTypeId) {
        this.$message.warning("请选择分类");
        return;
      }
      let url = "";
      let method = "";
      // 新增
      if (this.operation == 1) {
        url = "/hxclass-management/information/problem";
        method = "POST";
      }
      // 编辑
      else if (this.operation == 2) {
        url = "/hxclass-management/information/problem";
        method = "PUT";
      }
      this.PreventLoad = true;
      this.$ajax({
        url,
        method,
        params: {
          content: this.content,
          cpTypeId: this.cpTypeId,
          title: this.title,
          cpId: this.id,
        },
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败");
          this.$router.go(-1);
        }
      });
    },
    getDetails(e) {
      this.$ajax({
        url: "/hxclass-management/information/problem",
        method: "get",
        params: {
          id: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.content = res.data.content; // 内容
          this.cpTypeId = res.data.categoryId; // 分类id
          this.title = res.data.title; // 标题
        }
        // this.total = res.data.total;
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
    this.operation = this.$route.query.operation;
    if (this.operation != 1) {
      this.id = this.$route.query.id;
      this.getDetails(this.id);
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 24px;
      .all_left_name {
        width: 100px;
        text-align: right;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 120px;
        width: 600px;
      }

      .right_Div {
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}
</style>
